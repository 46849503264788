import React from "react";
import { Container, Row, Col, Image, Card, Button } from "react-bootstrap";

import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { PlusCircle } from "react-bootstrap-icons";
import { PlusCircleFill } from "react-bootstrap-icons";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

import Denplanlogo from "../img/denplan-logo-2020.svg";

function CustomToggle({ children, eventKey, callback }) {
  const currentEventKey = React.useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = currentEventKey === eventKey;
  return (
    <button
      type="button"
      aria-label="Open fees"
      onClick={decoratedOnClick}
      className="ml-auto"
    >
      {isCurrentEventKey ? (
        <PlusCircleFill color="#369E9A" size={24} />
      ) : (
        <PlusCircle color="#369E9A" size={24} />
      )}

      {children}
    </button>
  );
}

function fees() {
  return (
    <main>
      <section id="dentalfees" className="py-5">
        <Container className="p-5">
          <h1 className="display-4   servicetitle my-5">Dental fees</h1>

          <Accordion>
            <Card border="light" className="fees">
              <Card.Header
                style={{
                  backgroundColor: "inherit",
                  borderBottom: "1px solid #369e9a91",
                }}
              >
                <Row>
                  <h3 className="fees">Diagnosis</h3>
                  <CustomToggle eventKey="0"></CustomToggle>
                </Row>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {" "}
                  <ul className="lead">
                    <li>
                      <Row>
                        <Col className="lead fees2">Consultation</Col>
                        <Col>£50</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Routine Examination </Col>
                        <Col>£50</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          X-rays including bitewings (small), occlusal (medium),
                          lateral (large) – both sides
                        </Col>
                        <Col>£20</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          New patient examination
                        </Col>
                        <Col>£95</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Assessment Orthodontic, Implantology, Prosthetic
                          (pictures, models){" "}
                        </Col>
                        <Col>£100</Col>
                      </Row>
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card border="light">
              <Card.Header
                style={{
                  backgroundColor: "inherit",
                  borderBottom: "1px solid #369e9a91",
                }}
              >
                {" "}
                <Row>
                  <h3 className="fees"> Preventative/Periodontal Care</h3>
                  <CustomToggle eventKey="1"> </CustomToggle>
                </Row>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <ul className="lead">
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Scale & Polishing most patients will need this on a
                          routine basis to help prevent periodontal (gum
                          disease)
                        </Col>
                        <Col>£50</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Fissure Sealing (per tooth)
                        </Col>
                        <Col>£35</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Sports Mouth Guard & Night Guard
                        </Col>
                        <Col>£280</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Perio Treatment (periochip) includes pre-scaling &
                          application
                        </Col>
                        <Col>£90</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Hygienist Appointment over 18 years (Deposit of £10
                          required)
                        </Col>
                        <Col>£80</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Night guard</Col>
                        <Col>£280</Col>
                      </Row>
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card border="light">
              <Card.Header
                style={{
                  backgroundColor: "inherit",
                  borderBottom: "1px solid #369e9a91",
                }}
              >
                <Row>
                  <h3 className="fees">Restorative</h3>
                  <CustomToggle eventKey="3"></CustomToggle>
                </Row>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  {" "}
                  <ul className="lead">
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Amalgam (silver) 1 surface
                        </Col>
                        <Col>£80</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Amalgam (silver) 2 surfaces
                        </Col>
                        <Col>£100</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Amalgam (silver) large</Col>
                        <Col>£120</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Composite (white) 1 surface
                        </Col>
                        <Col>£100</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Composite (white) 2 surfaces
                        </Col>
                        <Col>£130</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Composite (white) 3 surfaces
                        </Col>
                        <Col>£180</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Airflow</Col>
                        <Col>£90</Col>
                      </Row>
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card border="light">
              <Card.Header
                style={{
                  backgroundColor: "inherit",
                  borderBottom: "1px solid #369e9a91",
                }}
              >
                <Row>
                  <h3 className="fees">Advanced Restoration Inc. Lab Fees</h3>
                  <CustomToggle eventKey="4"></CustomToggle>
                </Row>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  {" "}
                  <ul className="lead">
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Recement of Crowns/Bridges
                        </Col>
                        <Col>£50</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Gold Crown – on request only
                        </Col>
                        <Col>£600</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Bonded Crown, non-precious metal
                        </Col>
                        <Col>£395</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Bonded Crown, precious metal
                        </Col>
                        <Col>£550</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Zirconia</Col>
                        <Col>£700</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Preformed Post/Core</Col>
                        <Col>£200</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Airflow</Col>
                        <Col>£90</Col>
                      </Row>
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card border="light">
              <Card.Header
                style={{
                  backgroundColor: "inherit",
                  borderBottom: "1px solid #369e9a91",
                }}
              >
                <Row>
                  <h3 className="fees">Root Fillings (including x-rays)</h3>
                  <CustomToggle eventKey="5"></CustomToggle>
                </Row>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  {" "}
                  <ul className="lead">
                    <li>
                      <Row>
                        <Col className="lead fees2">Incisor/Canine</Col>
                        <Col>£320-350</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Premolar</Col>
                        <Col>£370-450</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Molar</Col>
                        <Col>£480-590</Col>
                      </Row>
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card border="light">
              <Card.Header
                style={{
                  backgroundColor: "inherit",
                  borderBottom: "1px solid #369e9a91",
                }}
              >
                <Row>
                  <h3 className="fees">Surgery</h3>
                  <CustomToggle eventKey="6"></CustomToggle>
                </Row>
              </Card.Header>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  {" "}
                  <ul className="lead">
                    <li>
                      <Row>
                        <Col className="lead fees2">Extractions – simple</Col>
                        <Col>£100</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Extractions - complex</Col>
                        <Col>£370-450</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Extractions - surgical</Col>
                        <Col>£280</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Upper or Lower acrylic dentures
                        </Col>
                        <Col>£850</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Upper or Lower set of acrylic dentures
                        </Col>
                        <Col>£1150</Col>
                      </Row>
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card border="light">
              <Card.Header
                style={{
                  backgroundColor: "inherit",
                  borderBottom: "1px solid #369e9a91",
                }}
              >
                <Row>
                  <h3 className="fees">Partial acrylic dentures</h3>
                  <CustomToggle eventKey="7"></CustomToggle>
                </Row>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  {" "}
                  <ul className="lead">
                    <li>
                      <Row>
                        <Col className="lead fees2">1-3 Teeth</Col>
                        <Col>£480</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">4-8 Teeth</Col>
                        <Col>£550</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">9+ Teeth</Col>
                        <Col>£680</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Valplast</Col>
                        <Col>£1000</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Partial Chrome Cobalt (metal)
                        </Col>
                        <Col>£1000</Col>
                      </Row>
                    </li>

                    <li>
                      <Row>
                        <Col className="lead fees2">Valplast</Col>
                        <Col>£1000</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Reline</Col>
                        <Col>£200-250</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Soft Reline</Col>
                        <Col>£225-275</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Denture Repair</Col>
                        <Col>£115</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Tooth addition (per tooth)
                        </Col>
                        <Col>£115</Col>
                      </Row>
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card border="light">
              <Card.Header
                style={{
                  backgroundColor: "inherit",
                  borderBottom: "1px solid #369e9a91",
                }}
              >
                <Row>
                  <h3 className="fees">Cosmetic</h3>
                  <CustomToggle eventKey="8"></CustomToggle>
                </Row>
              </Card.Header>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  {" "}
                  <ul className="lead">
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Whitening (home bleaching)
                        </Col>
                        <Col>£290</Col>
                      </Row>
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card border="light">
              <Card.Header
                style={{
                  backgroundColor: "inherit",
                  borderBottom: "1px solid #369e9a91",
                }}
              >
                <Row>
                  <h3 className="fees">Implant Treatments</h3>
                  <CustomToggle eventKey="9"></CustomToggle>
                </Row>
              </Card.Header>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  {" "}
                  <ul className="lead">
                    <li>
                      <Row>
                        <Col className="lead fees2">Dental Implants (each)</Col>
                        <Col>£2300</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">
                          Bone graft using BioOss
                        </Col>
                        <Col>£280</Col>
                      </Row>
                    </li>
                    <li>
                      <Row>
                        <Col className="lead fees2">Bioguide</Col>
                        <Col>£400</Col>
                      </Row>
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <p className="lead text-justify my-5">
            All fees are a starting from fee and may vary for each patient
          </p>
        </Container>
      </section>

      <section id="tabeo" className="py-5 sectionmin2">
        <Container className="p-5">
          <h1 className="display-4 servicetitle  my-5">Tabeo Finance</h1>

          <p className="lead text-justify">
            We think about all our patients and we are pleased to offer finance
            options through a trusted dental finance company. This means you can
            finance all of our high-quality private dental treatments (as long
            as it is over £350) and it will be interest free.
            <br />
            Below is a link to the finance companys calculator online where you
            can input your desired loan amount and see what the repayments work
            out as:
            <br />
          </p>
          <Col className="text-center">
            <Button
              variant="secundary"
              id="btn-contact-form"
              className="btn btn--accent2 btn--large "
            >
              <a
                className="lead"
                style={{ color: "inherit", fontFamily: "Nunito" }}
                href="https://lead.tabeo.co.uk/the-corner-dental-practice/finance"
                target="_blank"
                rel="noreferrer"
              >
                Calculate your finance
              </a>
            </Button>
          </Col>
        </Container>
      </section>

      <section id="denplan" className="py-5">
        <Container className="p-5">
          <h1 className="display-4  servicetitle">Dental Plans</h1>
          <Image
            className="my-5 rounded mx-auto d-block "
            alt="DenPlan Logo"
            src={Denplanlogo}
          />
          <p className="lead text-justify">
            Denplan is the UK’s leading dental payment plan specialist with over
            6,500 dentists and approximately 1.8 million patients across the UK.
            <br />
            Why choose Denplan?{" "}
          </p>
          <Row>
            <Col lg={4}>
              <h3 className="">Your dentist cares</h3>
              <ul className="lead text-justify p-0 pr-5">
                <li className="lead ">
                  You will receive quality preventive dental care and advice
                </li>
                <li className="lead  ">
                  Reassurance your practice takes part in the Denplan Quality
                  Programme, helping them to continually improve standards
                </li>
                <li className="lead ">
                  Access to over 6,500 member dentists through Find a Dentist
                </li>
              </ul>
            </Col>
            <Col lg={4}>
              <h3 className="text-justify">Plans to suit you</h3>
              <ul className="lead text-justify  p-0 pr-5">
                <li className="lead ">
                  A range of dental payment plans to suit most budgets
                </li>
                <li className="lead">
                  Plans for Children designed specifically for children’s oral
                  health
                </li>
                <li className="lead">
                  Dental injury and dental emergency cover with Supplementary
                  Insurance
                </li>
                <li className="lead">
                  An in-house Customer Advisor team to deal with any questions
                  you may have
                </li>
              </ul>
            </Col>
            <Col lg={4}>
              {" "}
              <h3 className="">No costly surprises</h3>
              <ul className="lead text-justify p-0 pr-5">
                <li className="lead  ">
                  You can spread the cost of routine treatment so you don’t
                  receive any unexpected dental bills
                </li>
                <li className="lead">
                  Interest-free loans to spread the cost of high-value private
                  treatment
                </li>
                <li className="lead">
                  A 24-Hour Worldwide Dental Emergency Helpline for assistance
                  at home or overseas
                </li>
              </ul>
            </Col>
          </Row>
          <p className="lead">
            Visit the Denplan
            <a style={{ color: "inherit" }} href="https://www.denplan.co.uk/">
              {" "}
              website
            </a>{" "}
            , or get in touch with us for more information on Denplan
          </p>
        </Container>
      </section>
    </main>
  );
}
export default fees;
