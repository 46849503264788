import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import "./Contact.css";
import Cqclogo from "../img/logo/CareQuality-Commission2-1.png";
import Bda from "../img/logo/BDA-Logo-300x134.png";
import Dwt from "../img/logo/Dental-Welness-Trust2.png";
import Gdc from "../img/logo/general-dental-council-300x134.png";
import Nhs from "../img/logo/NHS.png";
import * as Icon from "react-bootstrap-icons";
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed";
function Contact() {
  return (
    <main>
      <Container className='layout-4 py-5'>
        <section id='contact' className='layout-4 pt-5 sectionmin2'>
          <Container className='mt-2'>
            <Row>
              <Col md={6}>
                <ResponsiveEmbed>
                  <iframe
                    title='map'
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2366.8366350193483!2d-0.21534338401288652!3d53.6142167623137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48789085d1dfafa3%3A0xe18507d83aff7588!2sThe%20Corner%20Dental%20Practice!5e0!3m2!1ses!2sus!4v1610072159129!5m2!1ses!2sus'
                    width='660'
                    height='660'
                    frameBorder='0'
                    style={{ border: 0 }}
                    allowFullScreen=''
                    aria-hidden='false'
                    tabIndex='0'></iframe>
                </ResponsiveEmbed>
              </Col>
              <Col md={6}>
                <Container className='mb-5 text-center '>
                  <Row className='  mt-3 '>
                    <p className='lead  mt-5 '>
                      {" "}
                      Princess Street, Immingham, North East Lincolnshire DN40
                      1LR{" "}
                    </p>
                  </Row>{" "}
                  <Row className='d-flex justify-content-center'>
                    <a href='tel: 01469 572043' style={{ color: "inherit" }}>
                      {" "}
                      <p className='lead     '>01469 572043</p>
                    </a>
                  </Row>{" "}
                  <Row className='d-flex justify-content-center'>
                    <a
                      href='mailto:juana@thecornerdentalpractice.com'
                      style={{ color: "inherit" }}>
                      <p className='lead redu '>
                        info@thecornerdentalpractice.com
                      </p>
                    </a>
                  </Row>
                  <h3 className='display-5 servicetitle my-2'>Opening Hours</h3>
                  <p className='lead  my-3 redu'>
                    <Icon.CalendarDay size={36} /> Mon -Fri{"          "}
                    <Icon.ClockHistory size={36} className='m-2 ' />
                    09: 00 to 13: 00 <br />
                    14: 00 to 17: 30 <br />
                  </p>
                  <p className='lead d-flex justify-content-center'>
                    Saturday and Sunday closed
                  </p>
                </Container>
              </Col>
            </Row>
          </Container>
        </section>

        <section id='Logos'>
          <Container className='my-5  '>
            <Row className='my-5 justify-content-md-center '>
              <Col sm={4} md={2} className='my-3'>
                <Image
                  className='my-5  m-auto d-block'
                  src={Cqclogo}
                  alt='Cqclogo'
                />
              </Col>
              <Col sm={4} md={2} className='my-3'>
                <Image
                  className='my-5  m-auto d-block '
                  src={Bda}
                  alt='Bdalogo'
                />
              </Col>{" "}
              <Col sm={4} md={2} className='my-3'>
                <Image
                  className='my-5  m-auto d-block '
                  src={Dwt}
                  alt='Dwtlogo'
                />
              </Col>{" "}
              <Col sm={4} md={2} className='my-3'>
                <Image
                  className='my-5  m-auto d-block '
                  src={Gdc}
                  alt='Gdclogo'
                />
              </Col>{" "}
              <Col sm={4} md={2} className='my-3'>
                <Image
                  className='my-5  m-auto d-block '
                  src={Nhs}
                  alt='Nhslogo'
                />
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </main>
  );
}
export default Contact;
