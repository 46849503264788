import React from "react";
import "./Ourservices.css";

import { Container, Row, Col } from "react-bootstrap";

function Ourservices() {
  return (
    <main>
      <Container className='py-5'>
        <section id='hygiene' className='py-5'>
          <Container>
            <Row>
              <Col xs={4} md={2}>
                <span className='glyph text-center'>
                  <i
                    className=' glyph-icon flaticon-007-tooth-cleaning glyph2'
                    aria-hidden='true'
                  />
                </span>
              </Col>

              <Col xs={8} md={10}>
                <h3 className='display-4 servicetitle '>
                  Prophylaxis (teeth cleaning)
                </h3>
              </Col>
            </Row>

            <Row>
              <Col xs={0} md={2}></Col>

              <Col xs={12} md={10}>
                <p className='lead  text-justify'>
                  <br />
                  The accumulation of plaque and calculus around the teeth and
                  under the gums can cause a cascade of dental problems like
                  tooth decay, gingivitis, bone loss, halitosis (bad breath),
                  and eventually, tooth loss. Routine cleanings will prevent the
                  accumulation in the mouth of harmful bacteria and ensures you
                  can enjoy a lifetime of exceptional oral health.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id='airflow' className='py-5'>
          <Container>
            <Row>
              <Col xs={4} md={2}>
                <span className='glyph'>
                  <i
                    className=' glyph-icon flaticon-006-tooth-5 glyph2'
                    aria-hidden='true'
                  />
                </span>
              </Col>
              <Col xs={8} md={10} className='d-flex align-items-center'>
                <h3 className='display-4 servicetitle '>AIR-FLOW therapy</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={2} md={2}></Col>
              <Col xs={12} md={10}>
                <p className='lead text-justify'>
                  <br />
                  AIR-FLOW brightening is a new dental hygiene method that
                  removes stains and discolouration, which can be caused by
                  drinking red wine, tea and coffee and also smoking thereby
                  helping to restore a natural smile. It is thought in some
                  cases to help tackle bad breath too.{" "}
                </p>
                <p className='lead  text-justify'>
                  The AIR-FLO method uses a machine to clean and polish teeth
                  with a highly pressured blast of a combination of water,
                  compressed air and fine powder particles (usually lemon
                  flavour) through a small nozzle onto the surfaces of one’s
                  teeth.
                </p>
                <p className='lead  text-justify'>
                  It is thought to be much better than the traditional scale and
                  polish treatments at removing the damaging biofilm that
                  develops when dental plaque is colonised by bacteria whilst
                  leaving the surface of your teeth intact. This is due to the
                  innovative spray technology can access those nooks and
                  cranny`s of your teeth hard to reach areas that traditional
                  scaling cannot.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id='periodontology' className='py-5'>
          <Container>
            <Row>
              <Col xs={4} md={2}>
                <span className='glyph'>
                  <i
                    className=' glyph-icon flaticon-022-dental-care-1 glyph2'
                    aria-hidden='true'
                  />
                </span>
              </Col>
              <Col xs={8} md={10} className='d-flex align-items-center'>
                <h3 className='display-4 servicetitle '>Periodontology</h3>
              </Col>
            </Row>

            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={10}>
                {" "}
                <p className='lead  text-justify'>
                  <br />
                  Periodontal disease, most commonly known as gum disease, is in
                  simple terms inflammation of the gums surrounding the teeth.
                  Therefore, its main feature is gums characterised by red
                  rather than pink color with tendency to bleed during brushing.
                </p>
                <p className='lead  text-justify'>
                  Periodontal treatment is the specialist cleaning of your teeth
                  and gums to help control the bacteria that cause gum disease.
                  Gum disease is caused by the germs (bacteria) which live in
                  your mouth. The bacteria stick to your teeth, irritate the
                  gums and make them bleed. Gum disease can eventually destroy
                  the gum and bone which support your teeth. Some people get
                  more severe gum disease than others. This may have specific
                  causes such as diabetes or smoking, but some people are just
                  more likely to suffer from gum disease
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id='crowns' className='py-5'>
          <Container>
            <Row>
              <Col xs={4} md={2}>
                <span className='glyph'>
                  <i
                    className=' glyph-icon glyph2 flaticon-026-dental-1'
                    aria-hidden='true'
                  />
                </span>
              </Col>
              <Col xs={8} md={10} className='d-flex align-items-center'>
                <h3 className='display-4 servicetitle '>Crowns</h3>
              </Col>
            </Row>

            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={10}>
                <p className='lead  text-justify'>
                  <br />
                  Crowns are full-coverage restorations that are used to cover a
                  tooth that is likely to break or is too damaged to be
                  corrected with a traditional filling.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id='veneers' className='py-5'>
          <Container>
            <Row className=''>
              <Col xs={4} md={2}>
                <span className='glyph '>
                  <i
                    className=' glyph-icon glyph2 flaticon-018-dental-4'
                    aria-hidden='true'
                  />
                </span>
              </Col>
              <Col xs={8} md={10} className='d-flex align-items-center'>
                <h3 className='display-4 servicetitle '>Porcelain Veneers</h3>
              </Col>
            </Row>

            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={10}>
                <p className='lead  text-justify'>
                  <br />
                  Dental veneers are ultra-thin shells of ceramic (porcelain),
                  which are bonded straight onto the front of your teeth. This
                  procedure requires little or no anesthesia and can be the
                  ideal choice for improving the appearance of the front teeth.
                  Veneers are placed to mask discolorations, to brighten teeth
                  and to improve a smile.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id='inlays' className='py-5'>
          <Container>
            <Row>
              <Col xs={4} md={2}>
                <span className='glyph'>
                  <i
                    className=' glyph-icon glyph2 flaticon-030-tooth-1'
                    aria-hidden='true'
                  />
                </span>
              </Col>
              <Col xs={8} md={10} className='d-flex align-items-center'>
                <h3 className='display-4 servicetitle'>Inlays & Onlays</h3>
              </Col>
            </Row>

            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={10}>
                <p className='lead  text-justify'>
                  <br />
                  These restorations are a simple and effective treatment, and
                  in some cases are an excellent alternative to a complete
                  porcelain crown.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id='dentures' className='py-5'>
          <Container>
            <Row>
              <Col xs={4} md={2}>
                <span className='glyph'>
                  <i
                    className=' glyph-icon glyph2 flaticon-015-denture'
                    aria-hidden='true'
                  />
                </span>
              </Col>
              <Col xs={8} md={10} className='d-flex align-items-center'>
                <h3 className='display-4 servicetitle'>Dentures</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={10}>
                <p className='lead  text-justify'>
                  <br />
                  There are various kinds of dentures available, but all share a
                  common function: replacing teeth that have become loose or
                  lost. Dentures are a removable replacement for missing teeth
                  and can be either full or partial oral appliances. Natural,
                  existing teeth may need to be prepared for dentures to fit
                  properly. In some cases, teeth may need to be extracted and
                  your mouth given a chance to heal before new dentures can be
                  placed.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id='canals' className='py-5'>
          <Container>
            <Row>
              <Col xs={4} md={2}>
                <span className='glyph'>
                  <i
                    className=' glyph-icon glyph2 flaticon-011-dental-care-3'
                    aria-hidden='true'
                  />
                </span>
              </Col>
              <Col xs={8} md={10} className='d-flex align-items-center'>
                <h3 className='display-4 servicetitle'>Root Canals</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={10}>
                <p className='lead  text-justify'>
                  <br />
                  Root canal treatment (also called endodontics) is needed when
                  the blood or nerve supply of the tooth (known as the pulp) is
                  infected through decay or injury.
                </p>{" "}
                <p className='lead text-justify'>
                  If the pulp becomes infected, the infection may spread through
                  the root canal system of the tooth.
                </p>
                <p className='lead text-justify'>
                  This may eventually lead to an abscess. If root canal
                  treatment (RCT) is not carried out, the infection will spread
                  and the tooth may need to be taken out.
                </p>
                <p className='lead text-justify'>
                  The aim of the treatment is to remove all infection from the
                  root canal. The root is then cleaned and filled to prevent any
                  further infection. Root canal treatment is a skilled and
                  time-consuming procedure. Most courses of treatment will
                  involve two or more visits to your dentist.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id='surgery' className='py-5'>
          <Container>
            <Row>
              <Col xs={4} md={2}>
                <span className='glyph'>
                  <i
                    className=' glyph-icon glyph2 flaticon-023-dentist-1'
                    aria-hidden='true'
                  />
                </span>
              </Col>
              <Col xs={8} md={10} className='d-flex align-items-center'>
                <h3 className='display-4 servicetitle'>Oral Surgery</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={10}>
                <p className='lead  text-justify'>
                  <br />
                  Some teeth are extracted because they are severely decayed;
                  others may have advanced periodontal disease or have broken in
                  a way that cannot be repaired.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id='implantology' className='py-5'>
          <Container>
            <Row>
              <Col xs={4} md={2}>
                <span className='glyph'>
                  <i
                    className=' glyph-icon glyph2 flaticon-024-dental-implant'
                    aria-hidden='true'
                  />
                </span>
              </Col>
              <Col xs={8} md={10} className='d-flex align-items-center'>
                <h3 className='display-4 servicetitle'>Implantology</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={10}>
                <p className='lead  text-justify'>
                  <br />A dental implant is an artificial tooth root made of
                  titanium that a dentist gently places into your jaw. Dental
                  implants are ideal for people in good oral health who have
                  lost one or more teeth.
                </p>
                <p className='lead  text-justify'>
                  We know how confidence about your teeth can affect how you
                  feel about yourself, both personally and professionally.{" "}
                </p>
                <p className='lead  text-justify'>
                  Dental Implants have proven to be the answer to many patients’
                  dental problems. By replacing just one tooth in your mouth,
                  they enable the restoration of a natural smile, improving
                  patient comfort and confidence.{" "}
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id='whitening' className='py-5'>
          <Container>
            <Row>
              <Col xs={4} md={2}>
                <span className='glyph'>
                  <i
                    className=' glyph-icon glyph2 flaticon-012-tooth-4'
                    aria-hidden='true'
                  />
                </span>
              </Col>
              <Col xs={8} md={10} className='d-flex align-items-center'>
                <h3 className='display-4 servicetitle'>Whitening</h3>
              </Col>
            </Row>

            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={10}>
                <p className='lead  text-justify'>
                  <br />
                  Tooth whitening can be a very effective way of lightening the
                  natural colour of your teeth without removing any of the tooth
                  surface. It cannot make a complete colour change, but it may
                  lighten the existing shade. Our tooth whitening is a safe and
                  professional tooth whitening system guaranteed to give you
                  great results.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id='emergencies' className='py-5'>
          <Container>
            <Row>
              <Col xs={4} md={2}>
                <span className='glyph'>
                  <i
                    className=' glyph-icon glyph2 flaticon-019-gum'
                    aria-hidden='true'
                  />
                </span>
              </Col>
              <Col xs={8} md={10} className='d-flex align-items-center'>
                <h3 className='display-4 servicetitle'>Emergencies</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={10}>
                <p className='lead  text-justify'>
                  <br />
                  If you have emergency dental pain, please contact us as soon
                  as you can for advice. We will make every effort to sort out
                  your problem as quickly as possible.
                </p>
                <p className='lead  text-justify'>
                  There is no need to have attended the practice before either.
                  Just call as early as you can during surgery hours and speak
                  to reception.{" "}
                </p>
                <p className='lead  text-justify'>
                  For all emergency dental problem after surgery hours please
                  call NHS Direct on 111, and PRIVATE PATIENTS, PLEASE CALL
                  (mobile){" "}
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section id='other' className='py-5'>
          <Container>
            <Row>
              <Col xs={4} md={2}>
                <span className='glyph'>
                  <i
                    className=' glyph-icon glyph2 flaticon-025-dental-2'
                    aria-hidden='true'
                  />
                </span>
              </Col>
              <Col xs={8} md={10} className='d-flex align-items-center'>
                <h3 className='display-4 servicetitle'>Other Services:</h3>
              </Col>
            </Row>

            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={10}>
                <p className='lead  text-justify'>
                  <br />
                  Mouth & Sport Guards, TMH Therapy, Porcelain Bridges, Cosmetic
                  bonding, Sealants, Fluoride Treatment, white spot removal
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </main>
  );
}
export default Ourservices;
