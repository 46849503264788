import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import "../flaticon.css";

import {
  Button,
  Row,
  Container,
  Col,
  Image,
  Card,
  CardDeck,
} from "react-bootstrap";
import LazyHero from "react-lazy-hero";
import Background from "../img/clinic/ClinicaPorDentro.jpg";
import OurteamFinal from "../img/team/OurTeamFinal.png";
import Clinic from "../img/clinic/CasitaColorRedu.jpg";

import Smile from "../img/team/smile.png";
import { CookieBanner } from "@palmabit/react-cookie-law";

import app from "firebase/app";
import "firebase/analytics";

class Home extends Component {
  componentDidMount() {
    //put inside your constructor
  }

  render() {
    return (
      <main>
        <CookieBanner
          message=" This site uses cookies to store information on your computer. check your preferences"
          wholeDomain={true}
          showMarketingOption={false}
          onAccept={() => {
            app.analytics();
          }}
          privacyPolicyLinkText="For more info read our privacy policy"
          onAcceptPreferences={() => {}}
          onAcceptStatistics={() => {}}
          onAcceptMarketing={() => {}}
          policyLink="/privacy"
          showDeclineButton={true}
          styles={{
            dialog: {
              alignItems: "baseline",
              background: "#3b3b3b",
              color: "white",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              left: "0px",
              position: "fixed",
              width: "100%",
              zIndex: "999",
              bottom: "0px",
            },
          }}
        />

        {/* <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="TheCornerDentalPractice"
       buttonStyle={{
        color: " #212529",
          fontSize: "13px",
          borderColor: "rgb(129, 216, 207)",
          backgroundColor: "rgb(129, 216, 207)",
        }}
        expires={150}
      >      
        This site uses cookies. By continuing you are agreeing to our use of
        cookies. For details about cookies, how we use them and how to manage
        them please read our{" "}
        <a
          style={{
          color: "inherit",
          fontFamily: "inherit",
          fontSize: "inherit",
          }}
          href="/privacy"
        >
          {" "}
          privacy policy
        </a>

      </CookieConsent>
  */}
        <section id="hero" className="herofull">
          <LazyHero
            imageSrc={Background}
            minHeight={"100vh"}
            opactity={0.9}
            style={{ backgroundSize: "cover" }}
            color={"#369E9A"}
            parallaxOffset={30}
            className=""
          >
            <Container className="">
              <Row>
                <div className="my-2">
                  <h1 className="display-1 maintittle text-left text-light mt-2  ml-3 ">
                    THE CORNER <br /> DENTAL PRACTICE
                  </h1>
                  <h2 className="display-4  text-right mr-3  text-light  ">
                    Our purpose is our patients
                  </h2>
                </div>
              </Row>
            </Container>
          </LazyHero>
        </section>

        <section id="intro" className="py-5 sectionmin   d-flex  m-auto  ">
          <Container className="p-5 d-flex align-items-center mx-auto  ">
            <Row className="  mx-auto  ">
              <Col className="letter   ">
                <Image
                  src={Smile}
                  alt="Photo of Dr. Juana"
                  className="image-3"
                />
                <p className="letter-p b lead">Hello!</p>
                <p className="letter-p lead">
                  Welcome to The Corner Dental Practice.
                </p>
                <p className="letter-p lead">
                  We are family-run dental practice and have been providing
                  professional, high quality, routine dentistry for over 20
                  years in Immingham.
                </p>
                <p className="letter-p lead">
                  With the skills and knowledge we have gathered over many years
                  of working privately and within the NHS, we ensure your care
                  remains at the heart of everything we do.
                </p>
                <div className="div-block-8">
                  <p className="letter-p lead hand ">Dr. Juana Rivadeneyra</p>
                </div>
                <div className="div-block-9 text-center">
                  <Button
                    variant="secundary"
                    className="btn btn--accent2 btn--large ml-auto noclick  lettercardbut"
                  >
                    The Corner Dental Practice
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section id="hero2" className="herofull ">
          <LazyHero
            imageSrc={OurteamFinal}
            minHeight={"80vh"}
            opactity={0.9}
            color={"#6c757d"}
            parallaxOffset={20}
          >
            <Container className="py-5">
              <Row className="py-5">
                <div className="">
                  <h1 className="display-1 maintittle text-left text-light  ml-3 ">
                    Best quality dental care from the best team of specialists
                  </h1>
                  <Link to="/ourteam">
                    <Button
                      variant="secundary"
                      className="btn btn--accent2 btn--large ml-auto "
                    >
                      Meet our team
                    </Button>
                  </Link>
                </div>
              </Row>
            </Container>
          </LazyHero>
        </section>

        <section id="services" className="py-5  sectionmin">
          <Container className="py-5 ">
            <Row>
              <Col>
                <Container className="my-5">
                  <h1 className="display-4 servicetitle">Our services</h1>
                  <p className="lead text-justify">
                    <br />
                    At The Corner Dental Practice we offer a wide range of
                    general, cosmetic and specialist treatments to ensure the
                    dental health of our patients, whatever their needs.{" "}
                  </p>
                </Container>
              </Col>
            </Row>

            <CardDeck>
              <Card
                style={{ minWidth: "12rem" }}
                className="my-1 mr-3 text-center"
              >
                <Card.Body className="  ">
                  <Card.Title className="   ">
                    <span className="glyph2 p-2 iconsroundedcards mx-auto">
                      <i
                        className=" glyph-icon2 flaticon-010-smile   "
                        aria-hidden="true"
                      />
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <span className="icontext">Cosmetic </span>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                style={{ minWidth: "12rem" }}
                className="my-1  mr-3 text-center"
              >
                <Card.Body className=" ">
                  <Card.Title className="   ">
                    <span className="glyph2 p-2 iconsroundedcards mx-auto">
                      <i
                        className=" glyph-icon2 flaticon-035-baby"
                        aria-hidden="true"
                      />
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <span className="icontext">Family </span>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                style={{ minWidth: "12rem" }}
                className="my-1  mr-3  text-center"
              >
                <Card.Body className=" ">
                  <Card.Title className="   ">
                    <span className="glyph2 p-2 iconsroundedcards mx-auto">
                      <i
                        className="glyph-icon2 flaticon-031-broken-tooth"
                        aria-hidden="true"
                      />
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <span className="icontext">Restorative </span>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card
                style={{ minWidth: "12rem" }}
                className="my-1   mr-3 text-center"
              >
                <Card.Body className=" ">
                  <Card.Title className="   ">
                    <span className="glyph2 p-5 iconsroundedcards mx-auto">
                      <i
                        className="glyph-icon2 flaticon-023-dentist-1  "
                        aria-hidden="true"
                      />
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <span className="icontext">Surgery </span>
                  </Card.Text>
                </Card.Body>
              </Card>

              <Card
                style={{ minWidth: "12rem" }}
                className="my-1    mr-3   text-center"
              >
                <Card.Body className=" ">
                  <Card.Title className="   ">
                    <span className="glyph2 p-2 iconsroundedcards mx-auto">
                      <i
                        className="glyph-icon2  flaticon-024-dental-implant"
                        aria-hidden="true"
                      />
                    </span>
                  </Card.Title>
                  <Card.Text>
                    <span className="icontext">Implantology </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
            <p className="text-center my-5">
              {" "}
              <Link to="/ourservices">
                <Button
                  variant="secundary"
                  className="btn btn--accent2 btn--large ml-auto "
                >
                  See our services
                </Button>
              </Link>
            </p>
          </Container>
        </section>

        <section id="newpatient" className="py-5 sectionmin2">
          <Container>
            <Row>
              <Col md={12}>
                <div className="my-5">
                  <h1 className="display-4 servicetitle">
                    Do you want to become a New Patient?
                  </h1>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <Image className="" src={Clinic} alt=""></Image>
              </Col>
              <Col md={8}>
                <div className="my-5">
                  <p className="lead text-justify">
                    <br />
                    Offering both private and NHS services our priority is to
                    offer the highest standards of patient care.
                  </p>
                </div>
                <div className="text-center">
                  <Link to="/referrals#newpatients" className="">
                    <Button
                      variant="secundary"
                      className="btn btn--accent2 btn--large  "
                    >
                      New patients
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    );
  }
}
export default Home;
