import React from "react";
import { Button, Container, Carousel, Image, Row, Col } from "react-bootstrap";
import Rebecca from "../img/team/Rebecca_ccexpress2.png";
import Ebony from "../img/team/EbonyFenwick.png";
import Juana from "../img/team/Dr. Juana Rivadeneyra - Principal Dentist.png";
import Patricia from "../img/team/Dr. Patricia Chavesta - Oral Surgeon, Implantologist-Recuperado.png";
import Nicki from "../img/team/Nikky Lince - receptionist.png";
import Libbie from "../img/team/LibbieBingham.png";
import Clinic from "../img/clinic/CasitaAtardecer.jpg";
import Cqclogo from "../img/cqclogo.png";
import { Link } from "react-router-dom";

// import styles
import "./Ourteam.css";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need

import LazyHero from "react-lazy-hero";

function Ourteam() {
  return (
    <main className=" py-5">
      <section id="team2" className="layout-4 py-5 herofull">
        <Container className=" my-3">
          <Row>
            <Col md={6}>
              <Carousel className=" ">
                <Carousel.Item className="whitebg ">
                  <Image
                    src={Juana}
                    alt="Principal Dentist"
                    fluid
                    className=" "
                  />
                  <Carousel.Caption className="  ">
                    <h3 className="blackas text-left">Dr. Juana Rivadeneyra</h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="whitebg  ">
                  <Image
                    src={Patricia}
                    alt="Oral Surgeon, Implantologist"
                    className="zoom  align-text-bottom "
                  />
                  <Carousel.Caption className=" ">
                    <h3 className="blackas text-left">Dr. Patricia Chavesta</h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="whitebg ">
                  <Image
                    src={Rebecca}
                    className="mt-5 pt-5 "
                    fluid
                    alt="Dental Nurse"
                  />
                  <Carousel.Caption className="">
                    <h3 className="blackas text-left">Rebecca Agnew</h3>
                  </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item className="whitebg ">
                  <Image
                    src={Libbie}
                    className=" mt-5 pt-5    align-text-bottom"
                    fluid
                    alt="Dental Nurse"
                  />
                  <Carousel.Caption className=" ">
                    <h3 className="blackas  text-left">Libbie Bingham</h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="whitebg ">
                  <Image
                    src={Ebony}
                    className=" mt-5 pt-5    align-text-bottom"
                    fluid
                    alt="Dental Nurse"
                  />
                  <Carousel.Caption className=" ">
                    <h3 className="blackas  text-left">Ebony Fenwick</h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item className="whitebg ">
                  <Image src={Nicki} fluid alt="Receptionist" />
                  <Carousel.Caption className=" ">
                    <h3 className="blackas text-left">Nichola Lince</h3>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col md={6} className="  my-1  ">
              <h1 className="display-5  my-1  bluetif">Meet our Team</h1>
              <br />
              <h5 className="display-5">
                <strong> Dr. Juana Rivadeneyra </strong>
              </h5>
              <p className="lead-5">
                Principal Dentist
                <br />
                GDC reg.: 182622
                <br />
                Special interest in endodontics and implantology
              </p>
              <br />
              <h5 className="display-5">
                <strong> Dr. Patricia Chavesta</strong>
              </h5>
              <p className="lead-5">
                Associate Specialist
                <br />
                GDC reg.: 205525
                <br />
                Specialist in oral surgery, implantology and prosthodontics
              </p>
              <br />
              <h5 className="display-5">
                <strong>Rebecca Agnew</strong>
              </h5>
              <p className="lead-5">
                Dental Nurse
                <br />
                GDC reg.: 196936
                <br />
              </p>
              <br />
              <h5 className="display-5">
                <strong>Libbie Bingham</strong>
              </h5>
              <p className="lead-5">Trainee Dental Nurse</p>
              <br />
              <h5 className="display-5">
                <strong>Ebony Fenwick</strong>
              </h5>
              <p className="lead-5">Trainee Dental Nurse</p>
              <br />
              <h5 className="display-5">
                <strong>Nichola Lince</strong>
              </h5>
              <p className="lead-5">Receptionist</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="Facilities" className="tiffanybg2">
        <LazyHero
          imageSrc={Clinic}
          minHeight={"90vh"}
          maxWidth={"100%"}
          opactity={0.9}
          color={"#90D1CF"}
          parallaxOffset={100}
        >
          <Row className="p-3 mx-0">
            <h1 className="display-1 maintittle text-left text-light  ml-3 ">
              Our Practice
            </h1>
          </Row>
          <Row className="p-3 mx-0">
            <h5 className="display-4  text-light">
              Our clients are our priority, we offer quality dental services
              with a team of specialists.
            </h5>
          </Row>
          {/*   
          <LightGallery plugins={[lgZoom]} mode='lg-fade'>
            <a href='..' data-lg-size='' data-src={Clinic2} data-sub-html=''>
              <img alt='' className='m-1' src={Clinic2Small} />
            </a>
            <a href='..' data-lg-size='' data-src={Clinic4} data-sub-html=''>
              <img alt='' className='m-1' src={Clinic4Small} />
            </a>
            <a href='..' data-lg-size='' data-src={Clinic5} data-sub-html=''>
              <img alt='' className='m-1' src={Clinic5Small} />
            </a>
            <a href='..' data-lg-size='' data-src={Clinic6} data-sub-html=''>
              <img alt='' className='m-1' src={Clinic6Small} />
            </a>
            <a href='..' data-lg-size='' data-src={Clinic7} data-sub-html=''>
              <img alt='' className='m-1' src={Clinic7Small} />
            </a>
          </LightGallery>

          */}
          <div className="text-center">
            <Link to="/Gallery" className="">
              <Button
                variant="secundary"
                className="btn btn--accent2 btn--large  "
              >
                View Gallery
              </Button>
            </Link>
          </div>
        </LazyHero>
      </section>
      <section id="carequalitycommission" className="layout-4 py-5 my-5">
        <Container className="py-5 mt-5 ">
          <h1 className="display-4 servicetitle mt-5 ">
            Care Quality Commission Inspection
          </h1>
          <p className="lead text-justify my-3">
            The Corner Dental Practice is now registered with the Care Quality
            Commission as a provider of dental care services Our most recent
            inspection was: 17 December 2015 To view our profile, and for a
            summary of our Care Quality Commission Inspection Results, please{" "}
            <a
              style={{ color: "inherit" }}
              href="https://www.cqc.org.uk/location/1-426731928#icon_modal_green"
            >
              click here.
            </a>
            <br /> If you would like more information on the Care Quality
            Commission and what they do, please visit their website by{" "}
            <a style={{ color: "inherit" }} href="https://www.cqc.org.uk/">
              clicking here.
            </a>
          </p>

          <Image
            className="mt-5 rounded mx-auto d-block "
            src={Cqclogo}
            alt="Cqclogo"
          />
        </Container>
      </section>
    </main>
  );
}
export default Ourteam;
