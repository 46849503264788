import React from "react";
import "./Footer.css";
import * as Icon from "react-bootstrap-icons";
import { Row, Container, Col } from "react-bootstrap";

function Footer() {
  return (
    <footer>
      <Container className="p-3 ">
        <Row className="text-center">
          <Col>
            <h1 className="display-5  text-darks ">
              The Corner Dental Practice{" "}
            </h1>{" "}
          </Col>{" "}
        </Row>{" "}
        <Row>
          <Col md={6} className=" my-3">
            <div className="hours ">
              <h2 className="lead text-darks ml-5 pl-lg-5"> Opening Hours </h2>
              <Row>
                <Col xs={2}>
                  <p className="lead my-2 text-right ">
                    <Icon.CalendarDay size={32} color={"#212529"} />{" "}
                  </p>{" "}
                </Col>{" "}
                <Col xs={10} className="text-center ">
                  <p className="lead-5 my-2 text-left text-darks">
                    <> Mon - Fri </>{" "}
                  </p>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row>
                <Col xs={2}>
                  <p className="lead-4 my-2 text-right ">
                    <Icon.ClockFill size={32} color={"#212529"} />{" "}
                  </p>{" "}
                </Col>{" "}
                <Col xs={10}>
                  <p className="lead-5 my-2 text-left text-darks">
                    09: 00 to 13: 00 <br />
                    14: 00 to 17: 30 <br />
                    Saturday by appointment only{" "}
                  </p>{" "}
                </Col>{" "}
              </Row>{" "}
            </div>{" "}
          </Col>{" "}
          <Col md={6} className=" my-3">
            <div className="hours ">
              <h2 className="lead text-darks ml-5 pl-lg-5"> Contact us </h2>
              <Row>
                <Col xs={2}>
                  <p className="lead-5 my-2 text-right">
                    <Icon.GeoAltFill size={32} color={"#212529"} />{" "}
                  </p>{" "}
                </Col>{" "}
                <Col>
                  <p className="lead-5 my-2 text-left text-darks">
                    <>
                      {" "}
                      Princess Street, Immingham, North East Lincolnshire DN40 1
                      LR{" "}
                    </>{" "}
                  </p>{" "}
                </Col>{" "}
              </Row>
              <Row>
                <Col xs={2}>
                  <p className="lead-5 my-2 text-right">
                    <Icon.Phone size={32} color={"#212529"} />{" "}
                  </p>{" "}
                </Col>{" "}
                <Col>
                  <p className="lead-5 my-2 text-left text-darks">
                    <a
                      href="tel: 01469 572043"
                      className="footer-link"
                      style={{
                        color: "inherit",
                      }}
                    >
                      01469 572043{" "}
                    </a>{" "}
                  </p>{" "}
                </Col>{" "}
              </Row>
              <Row>
                <Col xs={2}>
                  <p className="lead-5 my-2 text-right">
                    <Icon.Mailbox size={32} color={"#212529"} />{" "}
                  </p>{" "}
                </Col>{" "}
                <Col>
                  <p className="lead-5 my-2 text-left text-darks">
                    <a
                      className="footer-link"
                      href="mailto:juana@thecornerdentalpractice.com"
                      style={{
                        color: "inherit",
                        fontSize: "90%",
                      }}
                    >
                      info @thecornerdentalpractice.com{" "}
                    </a>{" "}
                  </p>{" "}
                </Col>{" "}
              </Row>{" "}
            </div>{" "}
          </Col>{" "}
        </Row>{" "}
        <Row className="text-center ">
          <Col>
            <p className="paragraph-4 lead-5 text-darks">
              {" "}
              © Copyright 2023 •{" "}
              <a
                style={{
                  color: "inherit",
                }}
                href="/privacy"
                className="footer-link"
              >
                Privacy{" "}
              </a>{" "}
            </p>{" "}
          </Col>{" "}
        </Row>{" "}
      </Container>{" "}
    </footer>
  );
}

export default Footer;
