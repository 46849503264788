import React from "react";
import Navbarmain from "./components/Navbarmain";
import "./App.css";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import Ourservices from "./components/Ourservices";
import Ourteam from "./components/Ourteam";
import Privacy from "./components/Privacy";
import Contact from "./components/Contact";
import Referrals from "./components/Referrals";
import Fees from "./components/Fees";
import Testimonials from "./components/Testimonials";
import Gallery from "./components/Gallery";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/ourservices">
          <Navbarmain />
          <Ourservices />
          <Footer />
        </Route>
        <Route path="/privacy">
          <Navbarmain />
          <Privacy />
          <Footer />
        </Route>
        <Route path="/referrals">
          <Navbarmain />
          <Referrals />
          <Footer />
        </Route>
        <Route path="/ourteam">
          <Navbarmain />
          <Ourteam />
          <Footer />
        </Route>
        <Route path="/testimonials">
          <Navbarmain />
          <Testimonials />
          <Footer />
        </Route>
        <Route path="/fees">
          <Navbarmain />
          <Fees />
          <Footer />
        </Route>
        <Route path="/contact">
          <Navbarmain />
          <Contact />
          <Footer />
        </Route>
        <Route path="/Gallery">
          <Navbarmain />
          <Gallery />
          <Footer />
        </Route>
        <Route path="/">
          <Navbarmain />
          <Home />
          <Footer />
        </Route>
      </Switch>
    </Router>
  );
}
export default App;
