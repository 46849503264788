import React, { Component } from "react";

import {
  OverlayTrigger,
  Popover,
  Button,
  NavDropdown,
  Nav,
  Navbar,
} from "react-bootstrap";
import Logo from "../img/logo/VerdeLogo.png";
import "./navbarmain.css";

const popover = (
  <Popover id={"popover-positioned-bottom"}>
    <Popover.Title as="h3">
      Coronavirus update <br /> Message updated 4th January 2021
      <br />
      Following the Prime Minister's announcement on the 4th January, we confirm
      that we Remain Open during our normal opening hours.
    </Popover.Title>
    <Popover.Content className="">
      <p className="lead">
        As an essential service, our practice is still open and you are free to
        travel to us for your appointments, even during the current lockdown
        period. You can rest assured that we are still here to provide you with
        the same levels of care in the safest environment possible.
      </p>
      <p className="lead">
        We have introduced rigorous new protocols for how we see you once you’re
        in practice and have invested significantly in technology to ensure we
        can safely remain open throughout the Covid-19 pandemic.
      </p>
      <p className="lead">
        Whilst other aspects of your life are on hold, your dental health
        shouldn’t be one of them.
      </p>
      <p className="lead">
        We look forward to seeing you in the practice very soon. If you have any
        questions, please call.
      </p>
      <p className="lead">
        We appreciate your understanding at this unprecedented time.
      </p>
      <p className="lead">The Corner Dental Practice team</p>
    </Popover.Content>
  </Popover>
);

const Covidalert = () => (
  <OverlayTrigger
    trigger="click"
    rootClose
    placement="bottom"
    overlay={popover}
  >
    <Button
      variant="danger"
      className="mr-auto absolute-center covidalerts my-1"
    >
      COVID 19
      <br /> NEWS
    </Button>
  </OverlayTrigger>
);

class Navbarmain extends Component {
  /* state = {
    className: "transparent"
  };

  listenScrollEvent = e => {
    if (window.scrollY > 800) {
      this.setState({ className: "headerfix  headerbg " });
    } else {
      this.setState({ className: "headerfix   "});
    }
  };

  componentDidMount() {

    window.addEventListener("scroll", this.listenScrollEvent);

  }
*/
  render() {
    return (
      <Navbar
        variant="light"
        className="headerfix  headerbg pt-0 "
        fixed="top"
        bg=""
        collapseOnSelect
        expand="lg"
      >
        <Navbar.Brand href="/" className="headertext headertextbrand">
          <img
            alt="The Corner Dental Practice Logo"
            src={Logo}
            width="32"
            height="32"
            className="d-inline-block align-center headertext "
          />{" "}
          The Corner Dental Practice
        </Navbar.Brand>

        <div className=" ml-auto" />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          style={{
            maxWidth: "40vw",
          }}
        >
          <Nav className="headertext ml-auto">
            <NavDropdown
              title="Services"
              className="display-5 headertext navelement"
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item
                href="/ourservices"
                className="display-5 headertext navelement "
              >
                Our Services{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/ourservices#hygiene "
                className="display-5 headertext navelement"
              >
                Prophylaxis{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/ourservices#airflow"
                className="display-5 headertext navelement"
              >
                AIR-FLOW therapy
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/ourservices#periodontology"
                className="display-5 headertext navelement"
              >
                Periodontology
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/ourservices#crowns"
                className="display-5 headertext navelement"
              >
                Crowns
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/ourservices#veneers"
                className="display-5 headertext navelement"
              >
                Porcelain Veneers
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/ourservices#inlays"
                className="display-5 headertext navelement"
              >
                Inlays & Onlays
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/ourservices#dentures"
                className="display-5 headertext navelement"
              >
                Dentures
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/ourservices#canals"
                className="display-5 headertext navelement"
              >
                Root Canals
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/ourservices#surgery"
                className="display-5 headertext navelement "
              >
                Oral Surgery
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/ourservices#implantology"
                className="display-5 headertext navelement"
              >
                Implantology
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/ourservices#whitening"
                className="display-5 headertext navelement"
              >
                Whitening
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/ourservices#emergencies"
                className="display-5 headertext navelement"
              >
                Emergencies
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/ourservices#other"
                className="display-5 headertext navelement"
              >
                Other Services
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="display-5 headertext navelement" href="/fees">
              Our Fees
            </Nav.Link>
            <Nav.Link
              className="display-5 headertext navelement"
              href="/ourteam"
            >
              About Us
            </Nav.Link>
            <Nav.Link
              className="display-5 headertext navelement"
              href="/testimonials"
            >
              Patient Reviews
            </Nav.Link>

            <Nav.Link
              className="display-5 headertext navelement"
              href="/referrals"
            >
              Referrals
            </Nav.Link>
            <Nav.Link
              className="display-5 headertext navelement"
              href="/contact"
            >
              Contact us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
export default Navbarmain;
