import React from "react";
import { Alert } from "react-bootstrap";

function AlertDismissible2() {
  return (
    <Alert className='cookie-message container tiffanybg'>
      <h3 className='display-5'> Becoming an NHS patient </h3>
      <p className='lead '>
        We are currently not accepting new NHS registrations; If you are
        interested in becoming an NHS patient, contact us and we will add you on
        to our waiting list. We are however currently accepting new patients on
        a private basis.
      </p>
    </Alert>
  );
}
export default AlertDismissible2;
