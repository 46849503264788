import React from "react";
import { Container } from "react-bootstrap";

import Clinic4 from "../img/clinic/CasitaPanoramica.jpg";
import Clinic5 from "../img/clinic/ClinicaPorDentro.jpg";
import Clinic6 from "../img/clinic/ClinicaPorDentro2.jpg";
import Clinic7 from "../img/clinic/CasitaCompleta.jpg";

import Clinic4Small from "../img/clinic/small/CasitaPanoramica.jpg";
import Clinic5Small from "../img/clinic/small/ClinicaPorDentro.jpg";
import Clinic6Small from "../img/clinic/small/ClinicaPorDentro2.jpg";
import Clinic7Small from "../img/clinic/small/CasitaCompleta.jpg";

import "./Ourteam.css";

import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// import plugins if you need

import lgZoom from "lightgallery/plugins/zoom";

function Gallery() {
  return (
    <main className=' py-5'>
      <section id='gallery' className='layout-4 py-5'>
        <Container className=' my-3'>
          <LightGallery plugins={[lgZoom]} mode='lg-fade'>
            <a href='..' data-lg-size='' data-src={Clinic4} data-sub-html=''>
              <img alt='' className='m-1' src={Clinic4Small} />
            </a>
            <a href='..' data-lg-size='' data-src={Clinic5} data-sub-html=''>
              <img alt='' className='m-1' src={Clinic5Small} />
            </a>
            <a href='..' data-lg-size='' data-src={Clinic6} data-sub-html=''>
              <img alt='' className='m-1' src={Clinic6Small} />
            </a>
            <a href='..' data-lg-size='' data-src={Clinic7} data-sub-html=''>
              <img alt='Descripcion de la imagen aqui' className='m-1' src={Clinic7Small} />
            </a>
          </LightGallery>
        </Container>
      </section>
    </main>
  );
}
export default Gallery;
