import React from "react";
import { Link } from "react-router-dom";
import { Container, Button, Row, Col } from "react-bootstrap";
import AlertDismissible2 from "./AlertDismissible2";
function referrals() {
  return (
    <main>
      <section id='Pdf' className='layout-4 pt-5'>
        <Container className='my-5 py-5 '>
          <h1 className='display-4  servicetitle'>Patient Referrals</h1>
          <p className='lead'>
            If you want to refer one of your patients to any of our specialists,
            download the following form and fill it out and e-mail it to us or
            print it out and ask your patient to bring it along.
          </p>
          <Row className='text-center my-5'>
            <Col>
              <Button
                variant='secundary'
                id='btn-contact-form'
                className='btn btn--accent2 btn--large '>
                <a
                  className='lead'
                  style={{ color: "inherit", fontFamily: "Nunito" }}
                  href='/Patient Referral Form.pdf'
                  download>
                  Click to download
                </a>
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <section id='newpatients' className='layout-4 pb-5'>
        <Container className='my-5'>
          <h1 className='display-4  servicetitle'>New Patients</h1>
          <AlertDismissible2 />
          <Row className='text-center my-5'>
            <Col>
              <Link to='/contact'>
                <Button
                  variant='secundary'
                  className='btn btn--accent2 btn--large  mr-2 mr-md-5  '>
                  Contact us
                </Button>
              </Link>

              <Link to='/ourservices'>
                <Button
                  variant='secundary'
                  className='btn btn--accent2 btn--large   ml-5  mr-xs-3 '>
                  Our Services
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}
export default referrals;
