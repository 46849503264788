import React from "react";

import "./testimonials.css";
import { Container, CardColumns, Card, Image } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import Slide1 from "../img/Slide1.jpg";

import Slide2 from "../img/Slide2.jpg";

function testimonials() {
  return (
    <main>
      <Container className='layout-4 py-5'>
        <section id='Before' className='layout-5 pt-5 my-5'>
          <h1 className='display-4  servicetitle'>Before/After</h1>
          <p className='lead'>
            The Corner Dental Practice is re-imagining what the dental
            experience can be and proudly setting a new standard for patient
            care
          </p>
          <Container className=''>
            <Image
              className='my-5'
              alt='After/Before meet The Corner Dental Practice'
              src={Slide1}
            />
            <Image
              alt='After/Before meet The Corner Dental Practice'
              src={Slide2}
            />
          </Container>
        </section>

        <section id='reviews' className='my-5'>
          <Container>
            <h1 className='display-4  servicetitle'>Google reviews</h1>
            <p className='lead text-justify'>
              We love our patients, but don’t just take our word for it. Take a
              look at what our patients say about us, and maybe leave us a
              review and tell us about your experience at The Corner Dental
              Practice.
            </p>
            <Container className='my-5'>
              <CardColumns className='reviews'>
                <Card
                  md={6}
                  style={{
                    backgroundColor: "#81d8d0",
                    borderColor: "#81d8d0",
                  }}>
                  <blockquote className='blockquote mb-0 card-body'>
                    <p className='lead text-justify'>
                      Visited the practice yesterday for a filling after being
                      let down by my previous dentist. All the staff were
                      amazing and helpful from the receptionist to the dentist
                      herself. Everyone following strict covid-19 guidelines.
                      This practice is a shining exam of NHS dentistry at its
                      best. Keep it up guys!!!!
                    </p>
                    <Card.Text className='blockquote-footer'>
                      <small className=''>
                        Karen Rudden <cite title='Source Title'>Jan 2021</cite>{" "}
                      </small>
                    </Card.Text>
                    <Icon.StarFill />
                    <Icon.StarFill />
                    <Icon.StarFill />
                    <Icon.StarFill />
                    <Icon.StarFill />
                  </blockquote>
                </Card>

                <Card
                  md={6}
                  style={{
                    backgroundColor: "#81d8d0",
                    borderColor: "#81d8d0",
                  }}>
                  <blockquote className='blockquote mb-0 card-body lead'>
                    <p className='lead text-justify'>
                      Really good experience here every time. Staff are really
                      friendly and the doctors look really professional and
                      friendly too. Never had any issues contacting them, even
                      during the worst of COVID-19.{" "}
                    </p>
                    <Card.Text className='blockquote-footer'>
                      <small className='text-muted'>
                        Patrick S <cite title='Source Title'>Dec 2020</cite>
                      </small>{" "}
                    </Card.Text>
                    <Icon.StarFill />
                    <Icon.StarFill />
                    <Icon.StarFill />
                    <Icon.StarFill />
                    <Icon.StarFill />
                  </blockquote>
                </Card>

                <Card
                  md={6}
                  style={{
                    backgroundColor: "#81d8d0",
                    borderColor: "#81d8d0",
                  }}>
                  <blockquote className='blockquote mb-0 card-body lead'>
                    <p className='lead text-justify'>
                      The main dentist Juana is a brilliant dentist. the
                      receptionist Beverly is knowledgeable and helpful. The
                      nurses Bethany and Mel are friendly and will look after
                      you.{" "}
                    </p>
                    <Card.Text className='blockquote-footer'>
                      <small className='text-muted'>
                        Samira Lachehab{" "}
                        <cite title='Source Title'>Feb 2020</cite>{" "}
                      </small>
                    </Card.Text>
                    <Icon.StarFill />
                    <Icon.StarFill />
                    <Icon.StarFill />
                    <Icon.StarFill />
                    <Icon.StarFill />
                  </blockquote>
                </Card>
              </CardColumns>
            </Container>
          </Container>
        </section>
      </Container>
    </main>
  );
}
export default testimonials;
